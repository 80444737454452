@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
/*! component's theme wise override definitions and variables */
@keyframes hscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! hscroll icons */
.e-hscroll.e-rtl.e-scroll-device .e-nav-right-arrow::before {
  content: '\e904';
}

.e-hscroll.e-rtl.e-scroll-device .e-nav-left-arrow::before {
  content: '\e913';
}

.e-hscroll.e-rtl .e-nav-left-arrow::before {
  content: '\e913';
}

.e-hscroll.e-rtl .e-nav-right-arrow::before {
  content: '\e904';
}

.e-hscroll.e-scroll-device .e-nav-right-arrow::before {
  content: '\e913';
}

.e-hscroll.e-scroll-device .e-nav-left-arrow::before {
  content: '\e904';
}

.e-hscroll .e-nav-left-arrow::before {
  content: '\e904';
  line-height: normal;
}

.e-hscroll .e-nav-right-arrow::before {
  content: '\e913';
  line-height: normal;
}

/*! h-scroll layout */
.e-bigger .e-hscroll,
.e-hscroll.e-bigger {
  min-height: 56px;
}

.e-bigger .e-hscroll:not(.e-scroll-device),
.e-hscroll.e-bigger:not(.e-scroll-device) {
  padding: 0 50px;
}

.e-bigger .e-hscroll.e-scroll-device,
.e-hscroll.e-bigger.e-scroll-device {
  padding-right: 50px;
}

.e-bigger .e-hscroll.e-rtl.e-scroll-device,
.e-hscroll.e-bigger.e-rtl.e-scroll-device {
  padding-left: 50px;
  padding-right: initial;
}

.e-bigger .e-hscroll .e-nav-arrow.e-icons,
.e-hscroll.e-bigger .e-nav-arrow.e-icons {
  font-size: 14px;
}

.e-bigger .e-hscroll.e-rtl .e-scroll-overlay.e-scroll-right-overlay,
.e-hscroll.e-bigger.e-rtl .e-scroll-overlay.e-scroll-right-overlay {
  left: 50px;
}

.e-bigger .e-hscroll .e-scroll-overlay.e-scroll-right-overlay,
.e-hscroll.e-bigger .e-scroll-overlay.e-scroll-right-overlay {
  right: 50px;
}

.e-bigger .e-hscroll .e-scroll-nav,
.e-hscroll.e-bigger .e-scroll-nav {
  min-height: 56px;
  width: 50px;
}

.e-hscroll {
  display: block;
  position: relative;
  width: inherit;
}

.e-hscroll.e-rtl.e-scroll-device {
  padding-left: 50px;
  padding-right: initial;
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(-6px);
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-right-overlay {
  left: 56px;
  right: auto;
  transform: skewX(-16deg) translateX(-6px);
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-left-overlay {
  left: auto;
  right: 0;
}

.e-hscroll:not(.e-scroll-device) {
  padding: 0 40px;
}

.e-hscroll.e-scroll-device {
  padding-right: 50px;
}

.e-hscroll.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(6px);
  width: 56px;
}

.e-hscroll.e-scroll-device .e-scroll-nav .e-nav-arrow {
  font-size: 14px;
  transform: skewX(16deg);
}

.e-hscroll.e-scroll-device .e-scroll-overlay {
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 100;
}

.e-hscroll.e-scroll-device .e-scroll-overlay.e-scroll-right-overlay {
  left: auto;
  right: 56px;
  transform: skewX(-16deg) translateX(6px);
}

.e-hscroll.e-scroll-device .e-scroll-overlay.e-scroll-left-overlay {
  left: 0;
  right: auto;
}

.e-hscroll.e-overlay .e-hscroll-content > * {
  pointer-events: none;
}

.e-hscroll > * {
  height: inherit;
}

.e-hscroll .e-hscroll-content {
  display: inline-block;
  height: inherit;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
}

.e-hscroll .e-hscroll-content > * {
  pointer-events: auto;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-right-nav {
  left: 0;
  right: auto;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-left-nav {
  left: auto;
  right: 0;
}

.e-hscroll .e-scroll-nav {
  -ms-flex-align: center;
      align-items: center;
  bottom: 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 42px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.e-hscroll .e-scroll-nav.e-scroll-left-nav {
  left: 0;
}

.e-hscroll .e-scroll-nav.e-scroll-right-nav {
  right: 0;
}

.e-hscroll .e-scroll-nav.e-ie-align {
  display: table;
}

.e-hscroll .e-nav-arrow {
  position: relative;
}

.e-hscroll .e-nav-arrow.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.e-hscroll .e-hscroll-bar .e-hscroll-content .e-overlay {
  pointer-events: none;
}

/*! h-scroll theme */
.e-hscroll .e-icons {
  color: rgba(0, 0, 0, 0.54);
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.06);
}

.e-hscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.06);
}

.e-hscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
  color: #e3165b;
}

.e-hscroll .e-scroll-overlay {
  background-color: transparent;
  background-repeat: repeat-x;
}

.e-hscroll .e-scroll-overlay.e-scroll-left-overlay {
  background-image: linear-gradient(-270deg, #fafafa 0%, rgba(250, 250, 250, 0) 100%);
}

.e-hscroll .e-scroll-overlay.e-scroll-right-overlay {
  background-image: linear-gradient(-270deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}

.e-hscroll.e-rtl .e-scroll-nav {
  background: #fafafa;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-left-nav {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 0;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-right-nav {
  border-left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.e-hscroll.e-rtl .e-scroll-nav:hover {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  color: #000;
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  color: #000;
  border-color: rgba(0, 0, 0, 0.12);
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:active {
  background: #bdbdbd;
  border: 0;
  box-shadow: none;
  color: #000;
}

.e-hscroll .e-scroll-nav {
  background: #fafafa;
}

.e-hscroll .e-scroll-nav.e-scroll-left-nav {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.e-hscroll .e-scroll-nav.e-scroll-right-nav {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.e-hscroll .e-scroll-nav::after {
  background-color: transparent;
  border-radius: 50%;
  border-width: 1px;
  box-sizing: border-box;
  content: '';
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 1px;
}

.e-hscroll .e-scroll-nav:active::after {
  animation: hscroll-popup-shadow .6s ease-out 0ms;
  visibility: visible;
}

/*! component's theme wise override definitions and variables */
@keyframes vscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! vscroll icons */
.e-vscroll.e-scroll-device .e-nav-up-arrow::before {
  content: '\e85e';
}

.e-vscroll.e-scroll-device .e-nav-down-arrow::before {
  content: '\e84f';
}

.e-vscroll .e-nav-up-arrow::before {
  content: '\e910';
  line-height: normal;
}

.e-vscroll .e-nav-down-arrow::before {
  content: '\e916';
  line-height: normal;
}

/*! v-scroll layout */
.e-bigger .e-vscroll:not(.e-scroll-device),
.e-vscroll.e-bigger:not(.e-scroll-device) {
  padding: 24px 0;
}

.e-bigger .e-vscroll .e-icons,
.e-vscroll.e-bigger .e-icons {
  font-size: 18px;
}

.e-bigger .e-vscroll.e-rtl .e-scroll-overlay.e-scroll-down-overlay,
.e-vscroll.e-bigger.e-rtl .e-scroll-overlay.e-scroll-down-overlay {
  left: 24px;
}

.e-bigger .e-vscroll .e-scroll-overlay.e-scroll-down-overlay,
.e-vscroll.e-bigger .e-scroll-overlay.e-scroll-down-overlay {
  right: 24px;
}

.e-bigger .e-vscroll .e-scroll-nav,
.e-vscroll.e-bigger .e-scroll-nav {
  height: 24px;
}

.e-vscroll {
  display: block;
  position: relative;
  width: inherit;
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(-6px);
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-down-overlay {
  left: 56px;
  right: auto;
  transform: skewX(-16deg) translateX(-6px);
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-up-overlay {
  left: auto;
  right: 0;
}

.e-vscroll:not(.e-scroll-device) {
  padding: 0 16px;
}

.e-vscroll.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(6px);
  width: 56px;
  z-index: 1001;
}

.e-vscroll.e-scroll-device .e-scroll-nav .e-nav-arrow {
  font-size: 14px;
  transform: skewX(16deg);
}

.e-vscroll.e-scroll-device .e-scroll-overlay {
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 100;
}

.e-vscroll.e-scroll-device .e-scroll-overlay.e-scroll-down-overlay {
  left: auto;
  right: 56px;
  transform: skewX(-16deg) translateX(6px);
}

.e-vscroll.e-scroll-device .e-scroll-overlay.e-scroll-up-overlay {
  left: 0;
  right: auto;
}

.e-vscroll > * {
  height: inherit;
}

.e-vscroll .e-vscroll-content {
  display: inline-block;
  height: auto;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
  width: 100%;
}

.e-vscroll .e-vscroll-content > * {
  pointer-events: auto;
}

.e-vscroll.e-rtl .e-scroll-nav.e-scroll-up-nav {
  left: auto;
  right: 0;
}

.e-vscroll.e-rtl .e-scroll-nav.e-scroll-down-nav {
  left: 0;
  right: auto;
}

.e-vscroll .e-scroll-nav {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.e-vscroll .e-scroll-nav.e-scroll-up-nav {
  top: 0;
}

.e-vscroll .e-scroll-nav.e-scroll-down-nav {
  bottom: 0;
}

.e-vscroll .e-scroll-nav.e-ie-align {
  display: table;
}

.e-vscroll .e-nav-arrow {
  position: relative;
}

.e-vscroll .e-nav-arrow.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

/*! v-scroll theme */
.e-vscroll .e-icons {
  color: rgba(0, 0, 0, 0.54);
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.06);
}

.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.06);
}

.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
  color: #e3165b;
}

.e-vscroll .e-scroll-overlay {
  background-color: transparent;
  background-repeat: repeat-x;
}

.e-vscroll .e-scroll-overlay.e-scroll-up-overlay {
  background-image: linear-gradient(-270deg, #fafafa 0%, rgba(250, 250, 250, 0) 100%);
}

.e-vscroll .e-scroll-overlay.e-scroll-down-overlay {
  background-image: linear-gradient(-270deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}

.e-vscroll.e-rtl .e-scroll-nav {
  background: #fafafa;
}

.e-vscroll.e-rtl .e-scroll-nav:hover {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  border-color: rgba(0, 0, 0, 0.12);
  color: #000;
}

.e-vscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  color: #000;
}

.e-vscroll:not(.e-scroll-device) .e-scroll-nav:focus {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  border-color: rgba(0, 0, 0, 0.12);
  color: #000;
}

.e-vscroll:not(.e-scroll-device) .e-scroll-nav:active {
  background: #bdbdbd;
  border: 0;
  box-shadow: none;
  color: #000;
}

.e-vscroll .e-scroll-nav {
  background: #fafafa;
}

.e-vscroll .e-scroll-nav.e-scroll-up-nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.e-vscroll .e-scroll-nav.e-scroll-down-nav {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.e-vscroll .e-scroll-nav::after {
  background-color: transparent;
  border-radius: 50%;
  border-width: 1px;
  box-sizing: border-box;
  content: '';
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 1px;
}

.e-vscroll .e-scroll-nav:active::after {
  animation: vscroll-popup-shadow .6s ease-out 0ms;
  visibility: visible;
}

/*! component's theme wise override definitions and variables */
@keyframes hscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! component's theme wise override definitions and variables */
@keyframes vscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! component's theme wise override definitions and variables */
@keyframes tbar-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! toolbar icons */
.e-toolbar .e-popup-down-icon::before {
  content: '\e916';
  line-height: normal;
}

.e-toolbar .e-popup-up-icon::before {
  content: '\e910';
  line-height: normal;
}

/*! toolbar layout */
.e-bigger .e-toolbar,
.e-toolbar.e-bigger {
  height: 56px;
  min-height: 56px;
}

.e-bigger .e-toolbar .e-tbar-btn .e-icons,
.e-toolbar.e-bigger .e-tbar-btn .e-icons {
  font-size: 14px;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-tbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-tbar-extended {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-tbar-extended .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-tbar-extended .e-toolbar-extended {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.e-bigger .e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-bigger.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:last-child {
  margin: 0;
  margin-left: 3px;
}

.e-bigger .e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item,
.e-toolbar.e-bigger.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-left: 3px;
  margin-right: initial;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-left: 3px;
  margin-right: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:last-child {
  margin-left: 3px;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 3px;
}

.e-bigger .e-toolbar .e-hor-nav,
.e-toolbar.e-bigger .e-hor-nav {
  min-height: 56px;
  min-width: 40px;
}

.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 35px;
}

.e-bigger .e-toolbar .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon {
  line-height: 34px;
  min-height: 34px;
  min-width: 27px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  line-height: inherit;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item {
  height: 48px;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  min-width: 48px;
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  min-width: 27px;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn {
  min-height: 30px;
  padding: 0 16px;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin-left: 0;
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
  padding-right: 16px;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-items,
.e-toolbar.e-bigger .e-toolbar-items {
  min-height: 56px;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow {
  margin-left: 24px;
  margin-right: 24px;
  white-space: normal;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-multirow-separator,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-multirow-separator {
  display: none;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 3px;
}

.e-bigger .e-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 3px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item {
  min-height: 56px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-width: 48px;
  padding: 5px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item.e-separator,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item.e-separator {
  height: calc(100% - 20px);
  margin: 10px 5px;
  min-height: 36px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: calc(100% - 11px);
  margin: 5.5px 0;
  min-height: 0;
  min-width: 0;
  padding: 0 6px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon {
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 0 2.5px 0 2.5px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended {
  min-height: 56px;
  padding-bottom: 0;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 0;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-close,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended.e-popup-close {
  display: none;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-open,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended.e-popup-open {
  display: inline;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended {
  width: inherit;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended {
  box-shadow: none;
  display: inline;
  white-space: normal;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  min-width: 48px;
  padding: 5px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator.e-extended-separator,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator.e-extended-separator {
  display: none;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 56px;
  vertical-align: middle;
  width: auto;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 0 2.5px 0 2.5px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: calc(100% - 11px);
  margin: 5.5px 0;
  min-height: 0;
  min-width: 0;
  padding: 0 6px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 35px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  height: calc(100% - 20px);
  margin: 10px 5px;
  min-height: 36px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon {
  line-height: 34px;
  min-height: 34px;
  min-width: 27px;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-rtl .e-hor-nav,
.e-toolbar.e-bigger.e-extended-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-rtl .e-toolbar-extended {
  padding-right: 3px;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger.e-vertical .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-height: 38px;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  height: auto;
  margin: 5px 10px;
  min-height: auto;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  margin: 5.5px auto;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-bigger .e-toolbar.e-vertical .e-hor-nav,
.e-toolbar.e-bigger.e-vertical .e-hor-nav {
  min-height: 40px;
  min-width: 50px;
}

.e-toolbar {
  border-radius: 0;
  display: block;
  height: 42px;
  min-height: 42px;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}

.e-toolbar.e-extended-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
}

.e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended {
  padding-right: 3px;
}

.e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
  padding-left: 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar.e-extended-toolbar.e-extended-toolbar.e-tbar-extended {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.e-toolbar.e-extended-toolbar .e-hor-nav.e-ie-align {
  display: table;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 42px;
  padding-bottom: 0;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-toolbar-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-close {
  display: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-open {
  display: inline;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended {
  width: inherit;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  box-shadow: none;
  display: inline;
  white-space: normal;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  height: 100%;
  min-width: 34px;
  padding: 3.5px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator.e-extended-separator {
  display: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 42px;
  vertical-align: middle;
  width: auto;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 0 2px 0 2px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn-text {
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif";
  font-size: 14px;
  line-height: inherit;
  vertical-align: middle;
  width: auto;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: calc(100% - 10px);
  margin: 4px 0;
  min-height: 0;
  min-width: 0;
  padding: 0 1.5px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 25px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus {
  padding: 0 1.5px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover {
  padding: 0 1.5px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active {
  padding: 0 1.5px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  margin: 7.5px 3px;
  min-height: 25px;
  min-width: 1px;
  vertical-align: middle;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon:not(.e-toolbar-pop) {
  line-height: 25px;
  min-height: 25px;
  min-width: 24px;
  padding: 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-separator:last-of-type {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  height: auto;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 14px;
  vertical-align: middle;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item .e-tbar-btn {
  cursor: pointer;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif";
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding: 0 1.5px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-tbar-btn:first-child {
  display: inline-block;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item > * {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  text-overflow: ellipsis;
}

.e-toolbar.e-control[class*='e-toolbar'] {
  box-sizing: content-box;
}

.e-toolbar.e-toolpop .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar .e-tbar-btn-text,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  display: inline-block;
  padding: 0 2px 0 2px;
}

.e-toolbar.e-hidden,
.e-toolbar .e-toolbar-items .e-toolbar-item.e-hidden {
  display: none;
}

.e-toolbar.e-corner {
  border-radius: 0;
}

.e-toolbar .e-toolbar-pop {
  border-radius: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.e-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin: 0;
  width: auto;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item.e-toolbar-popup.e-hidden {
  display: none;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  min-height: 24px;
  padding: 0 16px;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin: 0;
  padding: 0;
  width: auto;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
  padding-right: 16px;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  min-width: 24px;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  min-width: 34px;
  padding: 0;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item.e-tbtn-align .e-btn.e-control {
  text-align: center;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item.e-tbtn-align .e-btn.e-control .e-icons.e-btn-icon {
  min-width: 100%;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -ms-flex-pack: center;
      justify-content: center;
  height: 36px;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item > * {
  height: 100%;
  min-width: 100%;
  text-overflow: ellipsis;
}

.e-toolbar .e-toolbar-pop .e-toolbar-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar .e-toolbar-pop .e-toolpopup {
  text-align: center;
}

.e-toolbar .e-toolbar-popup {
  text-align: center;
}

.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:last-child {
  margin: 0;
  margin-left: 3px;
}

.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-left: 3px;
  margin-right: initial;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-rtl .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: auto;
  right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  left: 0;
  right: auto;
}

.e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:last-child {
  margin-left: 3px;
}

.e-toolbar.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 3px;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:last-child {
  margin-left: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 3px;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-center .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:last-child {
  margin-left: 3px;
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:first-child {
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-item:last-child {
  margin-left: 3px;
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-item:last-child:last-child,
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-item:last-child:first-child,
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:first-child {
  margin-right: 3px;
}

.e-toolbar.e-rtl .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
  padding-left: 16px;
  padding-right: 0;
}

.e-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
  border-radius: 0 0 0 0;
}

.e-toolbar .e-hor-nav {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 0 0 0 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 42px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
}

.e-toolbar .e-hor-nav.e-ie-align {
  display: table;
}

.e-toolbar .e-popup-down-icon.e-icons,
.e-toolbar .e-popup-up-icon.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.e-toolbar .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 25px;
}

.e-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  line-height: 25px;
  min-height: 25px;
}

.e-toolbar .e-toolbar-items {
  border-radius: 0 0 0 0;
  display: inline-block;
  height: 100%;
  min-height: 42px;
  vertical-align: middle;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow {
  margin-bottom: 1px;
  margin-left: 18px;
  margin-right: 18px;
  white-space: normal;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-multirow-separator {
  display: none;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-left,
.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-center,
.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-right {
  display: inline;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar .e-toolbar-items.e-tbar-pos {
  display: block;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  display: table;
  height: 100%;
  top: 0;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  position: absolute;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  right: 0;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: 0;
  line-height: 35px;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center {
  margin: 0 auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-left,
.e-toolbar .e-toolbar-items .e-toolbar-center,
.e-toolbar .e-toolbar-items .e-toolbar-right {
  display: inline-block;
}

.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 3px;
}

.e-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 3px;
}

.e-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 3px;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-center .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-toolbar .e-toolbar-items:first-child > .e-toolbar-item:last-child,
.e-toolbar .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 3px;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 42px;
  vertical-align: middle;
  width: auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  height: 100%;
  min-width: 34px;
  padding: 3.5px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  margin: 7.5px 3px;
  min-height: 27px;
  min-width: 1px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item input[type='checkbox'] {
  height: auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  height: calc(100% - 10px);
  margin: 4px 0;
  min-height: 0;
  min-width: 0;
  padding: 0 1.5px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon {
  padding: 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin: 0;
  min-width: 24px;
  width: auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-item > * {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  text-overflow: ellipsis;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  height: calc(100% - 15px);
  vertical-align: middle;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator + .e-separator {
  display: none;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator:last-of-type, .e-toolbar .e-toolbar-items .e-toolbar-item.e-separator:first-of-type {
  display: none;
}

.e-toolbar .e-tbar-btn > :first-child {
  display: inline-block;
}

.e-toolbar .e-tbar-btn {
  border: none;
  cursor: pointer;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif";
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding: 0 1.5px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}

.e-toolbar .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 14px;
  vertical-align: middle;
}

.e-toolbar .e-tbar-btn div {
  vertical-align: middle;
}

.e-toolbar .e-tbar-btn .e-tbar-btn-text {
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif";
  font-size: 14px;
  line-height: inherit;
  vertical-align: middle;
  width: auto;
}

.e-toolbar.e-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.e-toolbar.e-vertical.e-rtl.e-tbar-pos .e-toolbar-left {
  bottom: 0;
  top: auto;
}

.e-toolbar.e-vertical.e-rtl.e-tbar-pos .e-toolbar-right {
  bottom: auto;
  top: 0;
}

.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-left,
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-center,
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  height: auto;
}

.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: auto;
  right: auto;
  top: 0;
}

.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  bottom: 0;
  left: auto;
  right: auto;
}

.e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-width: 33px;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  height: auto;
  margin: 3px 7.5px;
  min-height: auto;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  margin: 4px auto;
}

.e-toolbar.e-vertical .e-hor-nav {
  bottom: 0;
  height: auto;
  left: 0;
  min-height: 40px;
  min-width: 50px;
  right: auto;
  top: auto;
  width: auto;
}

/*! toolbar theme */
.e-toolbar {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fafafa;
  border: 1px none rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.e-toolbar.e-vertical .e-hor-nav {
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 1px 0 0 0;
}

.e-toolbar.e-vertical.e-rtl .e-hor-nav {
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  border-width: 0 0 0.5px 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-overlay {
  background: #fafafa;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0.5px 0 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons {
  color: rgba(0, 0, 0, 0.54);
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn {
  background: #fafafa;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:focus {
  background: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover {
  background: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active {
  background: #bdbdbd;
  border-color: #bdbdbd;
  border-radius: 0;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn-text {
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-icons {
  color: rgba(0, 0, 0, 0.54);
}

.e-toolbar .e-toolbar-pop {
  background: #fafafa;
  border: 1px none rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.21);
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn-text {
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar.e-toolpop .e-hor-nav.e-nav-active,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  border-left: 1px none rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.e-toolbar.e-toolpop .e-hor-nav.e-nav-active .e-icons,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) .e-icons {
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-tbar-btn {
  background: #fafafa;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-tbar-btn:focus {
  background: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-tbar-btn:hover {
  background: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-toolbar-items {
  background: #fafafa;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  background: #fafafa;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay .e-tbar-btn-text {
  color: rgba(0, 0, 0, 0.38);
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay .e-icons {
  color: rgba(0, 0, 0, 0.26);
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0.5px 0 0;
}

.e-toolbar.e-rtl .e-hor-nav {
  background: #fafafa;
  border: solid rgba(0, 0, 0, 0.12);
  border-left: 0;
  border-width: 0 1px 0 0;
}

.e-toolbar.e-rtl .e-hor-nav:not(.e-nav-active):hover {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-hor-nav {
  background: #fafafa;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 0 1px;
}

.e-toolbar .e-hor-nav:not(.e-expended-nav)::after {
  background-color: transparent;
  border-radius: 50%;
  border-width: 1px;
  box-sizing: border-box;
  content: '';
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 1px;
}

.e-toolbar .e-hor-nav:not(.e-expended-nav):active {
  border: 0;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-hor-nav:not(.e-expended-nav):active::after {
  animation: tbar-popup-shadow .6s ease-out 0ms;
  visibility: visible;
}

.e-toolbar .e-hor-nav:not(.e-expended-nav):hover {
  background: rgba(0, 0, 0, 0.12);
  border-left: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-toolbar .e-hor-nav:not(.e-expended-nav):focus {
  background: rgba(0, 0, 0, 0.12);
  border-left: 0;
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.12);
}

.e-toolbar .e-tbar-btn:active {
  background: #bdbdbd;
  border-color: #bdbdbd;
  border-radius: 0;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}

/*! component's theme wise override material-definitions and variables */
/*! accordion icons */
.e-accordion .e-tgl-collapse-icon::before {
  content: '\e916';
}

.e-accordion .e-tgl-collapse-icon.e-expand-icon {
  transform: rotate(-180deg);
}

/*! accordion layout */
.e-bigger .e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content,
.e-accordion.e-bigger .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 16px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel {
  font-size: 14px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-acrdn-content .e-accordion,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-acrdn-content .e-accordion {
  border: 0;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 46px 0 48px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header {
  padding: 0 46px 0 32px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-header .e-acrdn-header-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-header .e-acrdn-header-content {
  font-size: 14px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header {
  line-height: 47px;
  min-height: 48px;
  padding: 0 46px 0 16px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-toggle-icon,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-toggle-icon {
  height: 48px;
  min-height: 48px;
  min-width: 30px;
  right: 16px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon {
  display: inline-block;
  padding: 0 14px 0 0;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content {
  padding: 16px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 16px 16px 16px 48px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 16px 16px 16px 64px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-header {
  padding: 0 46px 0 32px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel.e-nested > .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 16px 48px 16px 16px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel.e-nested > .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 16px 64px 16px 16px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 48px 0 46px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header {
  padding: 0 32px 0 46px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header {
  padding: 0 16px 0 46px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  left: 16px;
  right: auto;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  padding: 0 0 0 14px;
}

.e-accordion {
  display: block;
  position: relative;
}

.e-accordion .e-acrdn-item.e-select.e-selected:first-child {
  border-top: 0;
}

.e-accordion .e-acrdn-item.e-select.e-selected:last-child {
  border-bottom: 0;
}

.e-accordion .e-acrdn-item > .e-acrdn-header .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item.e-selected {
  padding-top: 0;
}

.e-accordion .e-acrdn-item.e-selected > .e-acrdn-header .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item.e-overlay {
  height: auto;
}

.e-accordion .e-acrdn-item {
  overflow: hidden;
  padding-top: 0;
  position: relative;
}

.e-accordion .e-acrdn-item.e-hide {
  display: none;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 15px;
}

.e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  cursor: pointer;
}

.e-accordion .e-acrdn-item .e-acrdn-header {
  line-height: 35px;
  min-height: 36px;
  overflow: hidden;
  padding: 0 40px 0 16px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-accordion .e-acrdn-item .e-acrdn-header > * {
  display: inline-block;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  display: table;
  font-size: 12px;
  height: 36px;
  min-height: 36px;
  min-width: 24px;
  position: absolute;
  right: 16px;
  top: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-icon .e-tgl-collapse-icon.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-animation {
  transition: .5s ease 0s;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  display: inline-block;
  padding: 0 8px 0 0;
}

.e-accordion .e-acrdn-item .e-acrdn-panel {
  font-size: 13px;
  overflow-y: hidden;
  text-decoration: none;
  width: 100%;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion {
  border: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 40px 0 48px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select.e-selected .e-acrdn-header > .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-nested .e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 16px 16px 16px 48px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 16px 16px 16px 64px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content {
  line-height: 1.5;
  overflow: hidden;
  padding: 16px;
  text-overflow: ellipsis;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content > * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content .e-acrdn-header {
  padding: 0 40px 0 32px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content .e-acrdn-header-content {
  font-size: 14px;
}

.e-accordion .e-acrdn-item .e-content-hide {
  display: none;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 48px 0 40px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel .e-acrdn-content .e-acrdn-header {
  padding: 0 32px 0 40px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 16px 48px 16px 16px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 16px 64px 16px 16px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header {
  padding: 0 16px 0 40px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  left: 16px;
  right: auto;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  padding: 0 0 0 8px;
}

/*! accordion theme */
.e-accordion {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.e-accordion .e-active {
  background: #fff;
}

.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state {
  border-top: 1px solid #fff;
}

.e-accordion .e-acrdn-item.e-item-focus.e-expand-state.e-select, .e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state {
  border-color: #eee;
}

.e-accordion .e-acrdn-item.e-expand-state.e-select {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
}

.e-accordion .e-acrdn-item.e-overlay {
  background: #fff;
}

.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state > .e-acrdn-header:focus {
  background: #eee;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-header .e-acrdn-header-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-content-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-accordion .e-acrdn-item.e-expand-state.e-select:not(.e-selected) > .e-acrdn-header:focus {
  background: #eee;
}

.e-accordion .e-acrdn-item .e-acrdn-header:hover {
  background: transparent;
}

.e-accordion .e-acrdn-item .e-acrdn-header:active {
  background: transparent;
}

.e-accordion .e-acrdn-item .e-acrdn-header:focus {
  background: #eee;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header {
  background: #fff;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header > .e-toggle-icon,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header > .e-toggle-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-content {
  color: #e3165b;
}

.e-accordion .e-acrdn-item.e-select {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.e-accordion .e-acrdn-item.e-select.e-item-focus {
  border-color: #eee;
}

.e-accordion .e-acrdn-item.e-selected.e-select {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.e-accordion .e-acrdn-item.e-selected + .e-selected {
  border-top: 1px solid transparent;
}

.e-accordion .e-acrdn-item.e-selected .e-selected:last-child {
  border-bottom: 1px none rgba(0, 0, 0, 0.12);
}

.e-accordion .e-acrdn-item.e-selected > .e-acrdn-panel .e-acrdn-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-accordion .e-acrdn-item.e-selected > .e-acrdn-panel .e-acrdn-header-content {
  color: rgba(0, 0, 0, 0.54);
}

.e-accordion .e-acrdn-item .e-toggle-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-accordion .e-acrdn-item .e-acrdn-panel {
  font-size: 13px;
}

/*! contextmenu layout */
.e-contextmenu-wrapper ul .e-menu-item .e-previous::before {
  content: '\e977';
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret::before {
  content: '\e956';
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret::before {
  content: '\e937';
}

/*! contextmenu layout */
.e-contextmenu-wrapper ul {
  font-weight: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
  padding: 8px 0;
  border: none;
  border-radius: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: none;
  min-width: 120px;
  position: absolute;
}

.e-contextmenu-wrapper ul.e-ul,
.e-contextmenu-wrapper ul.e-ul * {
  box-sizing: border-box;
}

.e-contextmenu-wrapper ul.e-ul:focus,
.e-contextmenu-wrapper ul.e-ul *:focus {
  outline: none;
}

.e-contextmenu-wrapper ul.e-contextmenu {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-contextmenu-wrapper ul.e-ul {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}

.e-contextmenu-wrapper ul .e-menu-item {
  cursor: pointer;
  position: relative;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-hide {
  display: none;
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-url {
  text-decoration: none;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon {
  display: inline-block;
  vertical-align: middle;
}

.e-contextmenu-wrapper ul .e-menu-item.e-separator {
  cursor: auto;
  line-height: normal;
  pointer-events: none;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon {
  font-size: 14px;
  line-height: 36px;
  margin-right: 10px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret {
  line-height: 36px;
  margin-left: 16px;
  margin-right: 0;
  position: absolute;
  right: 8px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
  margin: 8px 0;
}

.e-contextmenu-wrapper ul .e-menu-item.e-blankicon {
  padding-left: 40px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret {
  font-size: 11px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-previous {
  margin-right: 16px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-disabled {
  cursor: auto;
  pointer-events: none;
}

.e-contextmenu-wrapper.e-sfcontextmenu {
  position: relative;
  width: 1px;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul {
  white-space: nowrap;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent {
  background: transparent;
  border: 0;
  box-shadow: none;
  height: 1px;
  left: 0;
  min-width: 0;
  padding: 0;
  top: 0;
  width: 0;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent .e-menu-item {
  height: 0;
  padding: 0;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent .e-menu-item.e-separator {
  border: 0;
  margin: 0;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-menu-icon {
  margin-right: 0;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret {
  margin-left: 0;
  margin-right: 16px;
  right: auto;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-menu-icon {
  margin-left: 10px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret {
  left: 8px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 16px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item.e-blankicon {
  padding-left: 16px;
  padding-right: 40px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-bigger .e-contextmenu-wrapper ul,
.e-bigger.e-contextmenu-wrapper ul {
  font-size: 15px;
  padding: 8px 0;
  white-space: nowrap;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  max-width: 280px;
  min-width: 112px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item {
  height: 48px;
  line-height: 48px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon {
  font-size: 16px;
  line-height: 48px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-caret {
  line-height: 48px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item.e-blankicon,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item.e-blankicon {
  padding-left: 42px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-caret {
  font-size: 12px;
}

.e-bigger .e-contextmenu-wrapper.e-rtl ul .e-blankicon,
.e-bigger.e-contextmenu-wrapper.e-rtl ul .e-blankicon {
  padding-left: 16px;
  padding-right: 42px;
}

.e-bigger .e-contextmenu-wrapper.e-rtl ul .e-blankicon.e-menu-caret-icon,
.e-bigger.e-contextmenu-wrapper.e-rtl ul .e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

/*! contextmenu theme */
.e-contextmenu-wrapper ul {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-header {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret {
  color: rgba(0, 0, 0, 0.54);
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-url {
  color: rgba(0, 0, 0, 0.87);
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
  outline: 0 solid rgba(0, 0, 0, 0.12);
  outline-offset: 0;
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused .e-caret {
  color: rgba(0, 0, 0, 0.54);
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused .e-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-contextmenu-wrapper ul .e-menu-item.e-selected {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
  outline: 0 solid #eee;
  outline-offset: 0;
}

.e-contextmenu-wrapper ul .e-menu-item.e-selected .e-caret {
  color: rgba(0, 0, 0, 0.54);
}

.e-contextmenu-wrapper ul .e-menu-item.e-selected .e-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-contextmenu-wrapper ul .e-disabled {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.e-contextmenu-wrapper ul .e-disabled .e-menu-icon {
  color: rgba(0, 0, 0, 0.38);
}

.e-contextmenu-wrapper ul .e-disabled .e-caret {
  color: rgba(0, 0, 0, 0.38);
}

.e-contextmenu-wrapper ul .e-disabled .e-menu-url {
  color: rgba(0, 0, 0, 0.38);
}

.e-contextmenu-wrapper ul .e-separator {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

/*! component's theme wise override definitions and variables */
@keyframes tbar-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! Material theme definition's */
/*! component's theme wise override definitions and variables */
/*! Horizontal Tab */
@keyframes tbar-alt-popup-shadow {
  0% {
    border-color: rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(0, 0, 0, 0.12);
  }
}

@keyframes hscroll-alt-popup-shadow {
  0% {
    border-color: rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(0, 0, 0, 0.12);
  }
}

/*! Material specific themes definition's */
/*! Vertical Tab */
/*! tab icons */
.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-rtl .e-nav-left-arrow::before {
  content: '\e913';
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-rtl .e-nav-right-arrow::before {
  content: '\e904';
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before {
  content: '\e904';
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  content: '\e913';
}

.e-tab .e-tab-header .e-close-icon::before {
  content: '\e7fc';
  position: relative;
}

.e-tab .e-tab-header .e-popup-up-icon::before {
  content: '\e916';
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-popup-up-icon::before {
    content: '\e984';
  }
}

.e-tab .e-tab-header .e-popup-down-icon::before {
  content: '\e916';
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-popup-down-icon::before {
    content: '\e984';
  }
}

@media screen and (max-width: 480px) {
  .e-tab.e-vertical-icon .e-tab-header .e-popup-up-icon::before {
    content: '\e82a';
  }
  .e-tab.e-vertical-icon .e-tab-header .e-popup-down-icon::before {
    content: '\e83d';
  }
}

.e-tab.e-vertical-tab .e-tab-header .e-scroll-nav .e-nav-up-arrow::before {
  content: '\e910';
}

.e-tab.e-vertical-tab .e-tab-header .e-scroll-nav .e-nav-down-arrow::before {
  content: '\e916';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-up-icon::before {
  content: 'More';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-up-icon::after {
  content: '\e919';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-down-icon::before {
  content: 'More';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-down-icon::after {
  content: '\e919';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-up-icon::before {
  content: '';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-up-icon::after {
  content: '\ebb9';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-down-icon::before {
  content: '';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-down-icon::after {
  content: '\ebb9';
}

/*! tab layout */
.e-bigger .e-tab .e-tab-header,
.e-tab.e-bigger .e-tab-header {
  height: 48px;
  min-height: 48px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items,
.e-tab.e-bigger .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: auto;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav {
  width: 50px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 48px;
  margin: 0;
  min-height: 48px;
  min-width: auto;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator) {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator).e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 72px;
  min-height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 48px;
  padding: 0 24px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 12px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 48px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-text {
  font-size: 14px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active {
  margin: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 48px;
  margin-top: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 48px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap {
  padding: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
  cursor: pointer;
  margin: 0 0 0 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
    margin: 0 0 0 14px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-close-icon::before {
  font-size: 10px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-close-icon::before {
    font-size: 12px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-icon::before {
  font-size: 20px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
  padding: 0 24px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
    margin: 0 0 0 14px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom {
  height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap {
  height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
  right: 24px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
  margin: 10px 0 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0 0 10px;
}

.e-bigger .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 22px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item {
  height: 48px;
  min-height: 48px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
  height: 48px;
  padding: 0 16px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
    padding: 0 16px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap {
  height: 48px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'],
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
  padding-right: 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'],
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
    padding-right: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before {
  top: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  right: 18px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  min-height: 48px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
    min-height: 48px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 48px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 48px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 0;
  padding-right: 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav {
  height: 48px;
  min-height: 48px;
  padding: 0 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-scroll-nav,
  .e-tab.e-bigger .e-tab-header .e-scroll-nav {
    padding: 0 12px;
  }
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-scroll-right-nav {
  padding: 0 12px;
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-scroll-left-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-scroll-left-nav {
  padding: 0 12px;
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-arrow {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
  .e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-arrow {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  font-size: 12px;
  line-height: 24px;
  top: 0;
  vertical-align: initial;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav,
.e-tab.e-bigger .e-tab-header .e-hor-nav {
  height: 48px;
  min-height: 48px;
  padding: 0 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav,
  .e-tab.e-bigger .e-tab-header .e-hor-nav {
    padding: 0 12px;
  }
}

.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
}

.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon::before {
  font-size: 12px;
  line-height: 24px;
  top: 0;
  vertical-align: initial;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 16px;
  }
}

.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
  top: 0;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
  top: 0;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 15px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active,
  .e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-text {
  padding-top: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child {
  margin: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child,
  .e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom {
  padding-bottom: 0;
  padding-top: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active .e-text-wrap {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 48px;
  padding: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-text-wrap {
  height: 48px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap {
  height: initial;
}

.e-bigger .e-tab .e-tab-header.e-vertical,
.e-tab.e-bigger .e-tab-header.e-vertical {
  max-width: 150px;
}

.e-bigger .e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before,
.e-tab.e-bigger .e-tab-header.e-vertical[style*='overflow: hidden']::before {
  bottom: 23px;
  top: 23px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before,
  .e-tab.e-bigger .e-tab-header.e-vertical[style*='overflow: hidden']::before {
    bottom: 0;
    top: 0;
  }
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device),
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device) {
  padding: 24px 0;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap {
  padding: 0 24px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  right: -24px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-scroll-nav,
.e-tab.e-bigger .e-tab-header.e-vertical .e-scroll-nav {
  height: 24px;
  min-height: 24px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow,
.e-tab.e-bigger .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 24px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  line-height: 46px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  font-size: 14px;
  line-height: 46px;
  margin: 0 0 0 10px;
}

.e-bigger .e-tab .e-tab-header.e-vertical.e-toolpop .e-toolbar-items,
.e-tab.e-bigger .e-tab-header.e-vertical.e-toolpop .e-toolbar-items {
  height: auto;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header,
.e-tab.e-bigger.e-vertical-icon .e-tab-header {
  height: 72px;
  min-height: 72px;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header .e-toolbar-items,
.e-tab.e-bigger.e-vertical-icon .e-tab-header .e-toolbar-items {
  height: 72px;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header .e-scroll-nav,
.e-tab.e-bigger.e-vertical-icon .e-tab-header .e-scroll-nav {
  height: 72px;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header .e-hor-nav,
.e-tab.e-bigger.e-vertical-icon .e-tab-header .e-hor-nav {
  height: 72px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-bigger .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-down-icon::before,
  .e-tab.e-bigger.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-bigger.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 12px;
  }
}

.e-bigger .e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items,
.e-tab.e-bigger.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-bigger .e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-scroll-nav,
.e-tab.e-bigger.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-scroll-nav {
  height: 24px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 48px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 48px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 72px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus {
  height: 48px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 48px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 48px;
}

.e-bigger .e-tab.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 48px;
  padding: 0;
}

.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}

.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 15px;
}

.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}

.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 13px;
}

.e-bigger .e-tab.e-safari .e-tab-header .e-close-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-close-icon::before {
  top: 0;
}

.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before {
  top: 0;
}

.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  top: 0;
}

.e-bigger .e-tab.e-fill .e-tab-header,
.e-tab.e-bigger.e-fill .e-tab-header {
  height: 46px;
  min-height: 46px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header,
  .e-tab.e-bigger.e-fill .e-tab-header {
    height: 46px;
    min-height: 46px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: 46px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items {
    min-height: 46px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 46px;
  margin: 0;
  min-height: 46px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator),
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 46px;
    min-height: 46px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 70px;
  min-height: 70px;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 46px;
  padding: 0 24px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 46px;
    padding: 0 24px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 46px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 46px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 46px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 46px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 46px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 46px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 70px;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  height: auto;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 24px;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 46px;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: 50px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 46px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 46px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 70px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
  .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
    height: auto;
  }
}

.e-bigger .e-tab.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-bigger .e-tab.e-fill.e-vertical-icon .e-tab-header,
.e-tab.e-bigger.e-fill.e-vertical-icon .e-tab-header {
  height: 70px;
  min-height: 70px;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  padding: 0 24px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 24px;
  }
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 48px;
}

.e-bigger .e-tab.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 24px;
}

.e-bigger .e-tab.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
  margin: 0 12px 0 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
    margin: 0 14px 0 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
  margin: 0 12px 0 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
    margin: 0 14px 0 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: 24px;
  right: auto;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-left: 22px;
  margin-right: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  padding-left: 12px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
    padding-left: 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  left: 18px;
  right: auto;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 12px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-hor-nav,
.e-bigger .e-tab.e-rtl .e-tab-header .e-scroll-right-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-hor-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-scroll-right-nav {
  padding: 0 12px;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-scroll-left-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-scroll-left-nav {
  padding: 0 12px;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  left: -24px;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 24px;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-popup-up-icon::after,
.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-popup-down-icon::after,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-popup-up-icon::after,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-popup-down-icon::after {
  margin: 0 10px 0 0;
}

.e-tab {
  display: block;
  position: relative;
}

.e-tab.e-hidden {
  display: none;
}

.e-tab.e-fill-mode .e-content,
.e-tab.e-fill-mode .e-content .e-item,
.e-tab.e-fill-mode .e-content .e-item > div,
.e-tab.e-fill-mode .e-content .e-item > div > .e-blazor-template {
  height: 100%;
}

.e-tab .e-tab-header {
  height: 36px;
  min-height: 36px;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header {
    height: 48px;
    min-height: 48px;
  }
}

.e-tab .e-tab-header::before {
  content: '';
  position: absolute;
}

.e-tab .e-tab-header:not(.e-vertical)::before {
  bottom: 0;
  top: 0;
  width: 100%;
}

.e-tab .e-tab-header .e-toolbar-items {
  height: auto;
  margin: 0;
  min-height: 36px;
  position: relative;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items {
    min-height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-scroll-device {
  padding: 0;
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-scroll-device .e-scroll-right-nav {
  display: none;
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav {
  width: 40px;
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0;
}

.e-tab .e-tab-header .e-hscroll-bar {
  overflow: hidden;
}

.e-tab .e-tab-header .e-indicator {
  display: block;
  position: absolute;
}

.e-tab .e-tab-header .e-indicator.e-hidden {
  display: none;
}

.e-tab .e-tab-header:not(.e-vertical) .e-indicator {
  bottom: 0;
  height: 2px;
  left: 0;
  right: 0;
  transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1);
}

.e-tab .e-tab-header .e-toolbar-item {
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.e-tab .e-tab-header .e-toolbar-item.e-hidden {
  display: none;
}

.e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 36px;
  margin: 0;
  min-height: 36px;
  min-width: auto;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 48px;
    min-height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 36px;
  padding: 0 18px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 48px;
    padding: 0 12px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  display: inherit;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
    font-size: 14px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-active {
  margin: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active {
    margin: 0;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 36px;
  margin-top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 500;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 36px;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
  display: none;
  margin: 0 0 0 10px;
  min-width: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
    margin: 0 0 0 14px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon,
.e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
  font-size: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-close-icon,
  .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
    font-size: 12px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
  top: 0;
}

.e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 20px;
  min-width: 20px;
  width: 20px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
    height: 20px;
    width: 20px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before {
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
  .e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before {
    font-size: 20px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 18px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
    padding: 0 24px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
    margin: 0 0 0 14px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-item.e-ibottom {
  height: 62px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-item.e-ibottom {
    height: 72px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  position: relative;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 72px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-text-wrap {
  display: block;
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap .e-text-wrap {
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
  position: absolute;
  right: 18px;
  top: calc(50% - 6px);
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
    right: 12px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
  margin: 8px 0 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
    margin: 10px 0 0;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0 0 8px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0 0 10px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-icon.e-icon-top,
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon.e-icon-bottom {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  margin: auto;
}

.e-tab .e-tab-header .e-toolbar-pop {
  overflow-y: auto;
  padding: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item {
  height: 36px;
  min-height: 36px;
  min-width: auto;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item {
    height: 48px;
    min-height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
  height: 36px;
  padding: 0 16px;
  text-align: initial;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
    height: 48px;
    padding: 0 16px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap {
  height: 36px;
  width: 100%;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  margin: 0 0 0 8px;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before {
  top: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
  padding-right: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
    padding-right: 0;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom {
  height: 36px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom {
    height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  min-height: 36px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
    min-height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 36px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 48px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-text-wrap {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  display: block;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 0 0 0 10px;
  padding-left: 0;
  padding-right: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    -ms-flex-pack: center;
        justify-content: center;
    margin: 0 0 0 12px;
    padding-left: 0;
    padding-right: 0;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  right: 18px;
  top: initial;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-icon.e-icon-top,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-icon.e-icon-bottom {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-tab .e-tab-header.e-close-show .e-icons.e-close-icon {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-close-show .e-icons.e-close-icon {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-active .e-close-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.e-tab .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 20px;
}

.e-tab .e-tab-header .e-scroll-nav {
  height: 36px;
  min-height: 36px;
  min-width: auto;
  width: auto;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav {
    height: 48px;
    min-height: 48px;
  }
}

.e-tab .e-tab-header .e-scroll-nav.e-scroll-left-nav {
  padding: 0 9px;
}

.e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav {
  padding: 0 9px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav {
    padding: 0 12px;
  }
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  font-size: 12px;
  line-height: 24px;
  position: relative;
  top: 0;
  vertical-align: initial;
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
}

.e-tab .e-tab-header .e-hor-nav {
  height: 36px;
  min-height: 36px;
  min-width: auto;
  padding: 0 9px;
  width: auto;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav {
    height: 48px;
    min-height: 48px;
    padding: 0 12px;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before {
  font-size: 12px;
  line-height: 24px;
  position: relative;
  top: 0;
  vertical-align: initial;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 16px;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
  top: 0;
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon {
    transform: none;
    transition: none;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
  transform: rotate(0deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
    transform: none;
    transition: none;
  }
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
  top: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom::before {
  bottom: auto;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}

.e-tab .e-tab-header.e-horizontal-bottom .e-indicator {
  bottom: auto;
  top: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
    margin: 0;
  }
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 36px;
  padding: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: initial;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap {
  height: initial;
  padding: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap::before, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap::before {
  bottom: auto;
  top: 0;
}

.e-tab .e-tab-header.e-vertical {
  max-width: 150px;
  z-index: 1;
}

.e-tab .e-tab-header.e-vertical::before {
  bottom: 0;
  height: 100%;
  left: 0;
  top: 0;
}

.e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before {
  bottom: 15px;
  height: auto;
  top: 15px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before {
    bottom: 0;
    top: 0;
  }
}

.e-tab .e-tab-header.e-vertical .e-indicator {
  display: block;
  transition: top 0.125s cubic-bezier(0.35, 0, 0.25, 1), bottom 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  width: 2px;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device) {
  padding: 16px 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap {
  padding: 0 18px;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-text-wrap {
  position: relative;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-text,
.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-icon::before {
  text-align: center;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-active .e-text-wrap::before {
  display: none;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  right: -18px;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-pop {
  top: initial !important;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left {
  float: left;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left::before {
  right: 0;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left .e-indicator {
  left: auto;
  right: 0;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right {
  float: right;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right::before {
  right: auto;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-indicator {
  left: 0;
  right: auto;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-tab-wrap {
  text-align: right;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-pop .e-tab-text {
  width: auto;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav {
  height: 16px;
  -ms-flex-pack: center;
      justify-content: center;
  min-height: 16px;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  margin: 0 auto;
  width: 16px;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 18px;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  height: 100%;
  transform: none;
  transition: none;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  float: left;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  text-transform: uppercase;
  transform: none;
  transition: none;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  float: left;
  font-size: 12px;
  line-height: 35px;
  margin: 0 0 0 10px;
  vertical-align: initial;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
  .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
    font-size: 14px;
  }
}

.e-tab .e-tab-header.e-vertical .e-scroll-device .e-scroll-nav,
.e-tab .e-tab-header.e-vertical .e-scroll-device .e-scroll-overlay {
  display: none;
}

.e-tab .e-tab-header.e-vertical.e-toolpop .e-toolbar-items {
  height: auto;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: -18px;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 18px;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  float: right;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  float: right;
  margin: 0 10px 0 0;
}

.e-tab .e-content {
  position: relative;
}

.e-tab .e-content .e-item.e-view {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.e-tab .e-content > .e-item {
  display: none;
}

.e-tab .e-content > .e-item.e-active {
  background: inherit;
  display: block;
}

.e-tab .e-content.e-progress {
  overflow-x: hidden;
}

.e-tab.e-vertical-tab .e-content {
  display: -ms-flexbox;
  display: flex;
}

.e-tab.e-vertical-tab .e-content .e-item.e-active {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
      flex: none;
}

.e-tab.e-vertical-tab .e-content .e-item,
.e-tab.e-vertical-tab .e-content .e-item > :first-child {
  width: 100%;
}

.e-tab.e-vertical-icon .e-tab-header {
  height: 62px;
  min-height: 62px;
}

.e-tab.e-vertical-icon .e-tab-header .e-toolbar-items {
  height: 62px;
}

.e-tab.e-vertical-icon .e-tab-header .e-scroll-nav {
  height: 62px;
}

.e-tab.e-vertical-icon .e-tab-header .e-hor-nav {
  height: 62px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 12px;
  }
}

.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item.e-active .e-tab-wrap::before,
.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
  display: none;
}

.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator):last-child.e-active .e-text-wrap {
  position: relative;
}

.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-scroll-nav {
  height: 16px;
}

.e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 36px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 36px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 62px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus {
  height: 36px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 36px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 36px;
}

.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 36px;
  padding: 0;
}

.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-template .e-content > .e-item {
  display: none;
}

.e-tab.e-template .e-content > .e-item.e-active {
  background: inherit;
  display: block;
}

.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align {
  display: -ms-flexbox;
  display: flex;
}

.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  display: block;
  line-height: 15px;
}

.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  display: block;
  line-height: 14px;
}

.e-tab.e-ie .e-tab-header .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-popup-down-icon {
  transform: none;
  transition: none;
}

.e-tab.e-ie .e-tab-header .e-popup-up-icon::before {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab.e-ie .e-tab-header .e-popup-up-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-ie .e-tab-header .e-popup-down-icon::before {
  transform: rotate(0deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab.e-ie .e-tab-header .e-popup-down-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align {
  display: -ms-flexbox;
  display: flex;
}

.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  display: block;
  line-height: 14px;
  position: relative;
}

.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  display: block;
  line-height: 11px;
  position: relative;
}

.e-tab.e-edge .e-tab-header .e-popup-up-icon {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-up-icon {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-popup-down-icon {
  transform: rotate(0deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon {
    transform: none;
    transition: none;
  }
}

@media screen and (max-width: 480px) and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-popup-up-icon::before {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-up-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-popup-down-icon::before {
  transform: rotate(0deg);
  transition: transform 0.1s ease;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-safari .e-tab-header .e-close-icon::before {
  top: -1px;
}

.e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before {
  top: 0;
}

.e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  top: 0;
}

.e-tab.e-disable {
  pointer-events: none;
}

.e-tab.e-fill .e-tab-header {
  height: 34px;
  min-height: 34px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header {
    height: 46px;
    min-height: 46px;
  }
}

.e-tab.e-fill .e-tab-header .e-indicator {
  display: none;
}

.e-tab.e-fill .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: 34px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-items {
    min-height: 46px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 34px;
  margin: 0;
  min-height: 34px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 46px;
    min-height: 46px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 60px;
  min-height: 60px;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 34px;
  padding: 0 18px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 46px;
    padding: 0 18px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 34px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 46px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 34px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 46px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 500;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 36px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 46px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 60px;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}

.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  height: auto;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  padding: 0;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item.e-itop, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-itop, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-itop {
  padding-top: 0;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin-right: 0;
  padding: 0;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 34px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
    height: 46px;
  }
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: 44px;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}

.e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 18px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 34px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 34px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 60px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
    height: auto;
  }
}

.e-tab.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab.e-fill.e-vertical-icon .e-tab-header {
  height: 60px;
  min-height: 60px;
}

.e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  padding: 0 18px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 24px;
  }
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 36px;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 500;
}

.e-tab.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 18px;
}

.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active,
.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  padding: 0;
}

.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0;
  padding: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item.e-active .e-text-wrap {
  height: 36px;
}

.e-tab.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
  margin: 0 10px 0 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
  margin: 0 10px 0 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: 18px;
  right: auto;
}

.e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-left: 20px;
  margin-right: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  padding-left: 10px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
    padding-left: 0;
  }
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  left: 16px;
  right: auto;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  left: 18px;
  right: auto;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 10px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 10px;
    padding-right: 0;
  }
}

.e-tab.e-rtl .e-tab-header .e-hor-nav,
.e-tab.e-rtl .e-tab-header .e-scroll-right-nav {
  padding: 0 9px;
}

.e-tab.e-rtl .e-tab-header .e-scroll-left-nav {
  padding: 0 9px;
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  display: none;
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  margin: 0;
  width: 100%;
}

/*! tab theme */
.e-tab {
  background: transparent;
  border: none;
}

.e-tab .e-tab-header {
  background: inherit;
  border: 0;
  border-radius: 0;
  border-bottom: 0;
}

.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
  border-bottom: initial;
}

.e-tab .e-tab-header .e-toolbar-items {
  background: inherit;
}

.e-tab .e-tab-header .e-indicator {
  background: #e3165b;
}

.e-tab .e-tab-header .e-toolbar-item {
  background: inherit;
  border: none;
}

.e-tab .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(227, 22, 91, 0.12);
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  border-radius: 0;
  color: rgba(0, 0, 0, 0.54);
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: rgba(0, 0, 0, 0.54);
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    color: rgba(0, 0, 0, 0.54);
  }
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
    color: rgba(0, 0, 0, 0.54);
  }
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-close-icon {
    color: rgba(0, 0, 0, 0.87);
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
  background: inherit;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background: inherit;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-toolbar-item.e-active {
  border: none;
  border-radius: 0;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #e3165b;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #e3165b;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-icon, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-icon {
  color: #e3165b;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-text, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-text {
  color: #e3165b;
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon:active {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay {
  background: inherit;
  opacity: 1;
  pointer-events: none;
}

.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay .e-tab-icon {
  color: rgba(0, 0, 0, 0.38);
}

.e-tab .e-tab-header .e-toolbar-pop {
  background: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.21);
  overflow-y: auto;
}

.e-tab .e-tab-header .e-toolbar-pop[e-animate='true'] {
  overflow-y: hidden;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop {
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.21);
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {
  background: #eee;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active {
  background: #eee;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-disable.e-overlay .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-disable.e-overlay .e-tab-icon {
  color: rgba(0, 0, 0, 0.38);
}

.e-tab .e-tab-header .e-scroll-nav,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  background: inherit;
  border: 0;
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:hover {
  background: initial;
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow:active,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:active {
  box-shadow: none;
}

.e-tab .e-tab-header .e-scroll-nav:focus .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-scroll-nav:focus .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow:hover {
  background: initial;
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-scroll-nav.e-overlay .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-overlay .e-nav-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.e-tab .e-tab-header .e-scroll-nav.e-overlay .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-overlay .e-nav-arrow:hover {
  color: rgba(0, 0, 0, 0.54);
}

.e-tab .e-tab-header .e-hor-nav,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) {
  background: transparent;
  border: 0;
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover {
  background: initial;
  border: initial;
  border-radius: 0;
}

.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon {
  background: rgba(0, 0, 0, 0.12);
  border: initial;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:hover {
  background: initial;
  border: initial;
  border-radius: 0;
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:active,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:active {
  background: initial;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.e-tab .e-tab-header .e-hor-nav:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):hover {
  border: 0;
}

.e-tab .e-tab-header .e-hor-nav:hover:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):hover:active {
  background: transparent;
}

.e-tab .e-tab-header .e-hor-nav.e-nav-active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav).e-nav-active {
  background: inherit;
  border: 0;
  box-shadow: none;
}

.e-tab .e-tab-header.e-horizontal-bottom {
  border-bottom: 0;
  border-top: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  border-bottom: 0;
  border-radius: 0;
  border-top: initial;
  border-top-color: inherit;
}

.e-tab .e-tab-header.e-vertical::before {
  border-width: 0 1px 0 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-active {
  border-bottom: 0;
  border-top: 0;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left .e-toolbar-item.e-active {
  border-radius: 0;
  border-right: initial;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-item.e-active {
  border-left: initial;
  border-radius: 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-pop {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav {
  background: inherit;
  border: 1px solid transparent;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav.e-scroll-up-nav {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav.e-scroll-down-nav {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow:hover {
  background: none;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover {
  background: rgba(0, 0, 0, 0.12);
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow:hover {
  background: none;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus {
  background: rgba(0, 0, 0, 0.12);
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  background: none;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow:hover {
  background: none;
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon {
  background: inherit;
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:hover, .e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:active, .e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:active:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:active,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:active:hover {
  background: inherit;
  border: 0;
  box-shadow: none;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav:hover .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav:hover .e-popup-down-icon {
  border: 0;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: rgba(227, 22, 91, 0.12);
}

.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text {
  color: #e3165b;
}

.e-tab.e-focused .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab.e-focused .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-focused .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab.e-focused .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  border: 0;
}

.e-tab.e-focused .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab.e-focused .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  border: 0;
}

.e-tab.e-focused .e-tab-header.e-vertical .e-hor-nav:focus {
  outline: 0;
}

.e-tab.e-disable {
  pointer-events: none;
}

.e-tab.e-disable .e-tab-header .e-indicator {
  background: rgba(227, 22, 91, 0.38);
}

.e-tab.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: rgba(0, 0, 0, 0.38);
}

.e-tab.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: rgba(227, 22, 91, 0.38);
}

.e-tab.e-disable .e-content {
  opacity: 0.38;
}

.e-tab.e-fill .e-tab-header {
  border-bottom: 2px Solid #e3165b;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item {
  border: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(0, 0, 0, 0.05);
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: rgba(0, 0, 0, 0.54);
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: rgba(0, 0, 0, 0.87);
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  margin-top: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active {
  border: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  background: #e3165b;
  border-radius: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: white;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  margin-top: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: white;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-close-icon {
  color: rgba(255, 255, 255, 0.7);
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-close-icon:hover {
  color: white;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom {
  border-bottom: 0;
  border-top: 2px Solid #e3165b;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  margin-top: 0;
}

.e-tab.e-fill .e-tab-header.e-vertical {
  border-bottom: 0;
}

.e-tab.e-fill .e-tab-header.e-vertical.e-vertical-left {
  border-right: 2px Solid #e3165b;
}

.e-tab.e-fill .e-tab-header.e-vertical.e-vertical-right {
  border-left: 2px Solid #e3165b;
}

.e-tab.e-fill.e-corner .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  border-radius: 3px 3px 0 0;
}

.e-tab.e-fill.e-corner .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap {
  border-radius: 0 0 3px 3px;
}

.e-tab.e-fill.e-disable .e-tab-header {
  border-bottom: 2px Solid rgba(227, 22, 91, 0.38);
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: rgba(0, 0, 0, 0.38);
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active {
  opacity: 1;
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: rgba(227, 22, 91, 0.38);
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: rgba(255, 255, 255, 0.38);
}

.e-tab.e-fill.e-disable .e-tab-header.e-horizontal-bottom {
  border-bottom: 0;
  border-top: 2px Solid rgba(227, 22, 91, 0.38);
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-close-icon {
  color: #e3165b;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: rgba(227, 22, 91, 0.8);
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: white;
}

.e-tab.e-background .e-tab-header {
  background: #3f51b5;
  border: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-items {
  background: #3f51b5;
}

.e-tab.e-background .e-tab-header .e-indicator {
  background: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item {
  border: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(0, 0, 0, 0.1);
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  background: #3f51b5;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
  background: inherit;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: white;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-close-icon:hover, .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-close-icon:active {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-item .e-close-icon {
  color: rgba(255, 255, 255, 0.7);
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-close-icon {
  color: rgba(255, 255, 255, 0.54);
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-close-icon:hover {
  color: #e3165b;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active {
  border: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  background: #3f51b5;
  border-radius: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: white;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: inherit;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: white;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: white;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-close-icon {
  color: rgba(255, 255, 255, 0.7);
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-close-icon:hover {
  color: white;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap {
  background: rgba(63, 81, 181, 0);
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-close-icon {
  color: rgba(255, 255, 255, 0.38);
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item {
  background: #3f51b5;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: rgba(255, 255, 255, 0.7);
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {
  background: rgba(255, 255, 255, 0.12);
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: white;
}

.e-tab.e-background .e-tab-header .e-scroll-nav .e-nav-arrow {
  color: rgba(255, 255, 255, 0.7);
}

.e-tab.e-background .e-tab-header .e-scroll-nav .e-nav-arrow:hover {
  background: inherit;
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-scroll-nav:focus .e-nav-arrow:hover {
  background: inherit;
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-scroll-nav:active::after {
  animation: hscroll-alt-popup-shadow 1s ease-out 0ms;
}

.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-down-icon {
  color: rgba(255, 255, 255, 0.7);
}

.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-hor-nav:active::after {
  animation: tbar-alt-popup-shadow 1s ease-out 0ms;
}

.e-tab.e-background .e-tab-header .e-hor-nav.e-nav-active .e-popup-up-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  border: 0;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  border: 0;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow:hover {
  background: none;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow {
  color: #fff;
}

.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon {
  background: inherit;
  border-color: transparent;
}

.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:hover {
  background: inherit;
  border-color: transparent;
}

.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  border-color: transparent;
}

.e-tab.e-background.e-disable .e-tab-header .e-indicator {
  background: rgba(255, 255, 255, 0.38);
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: rgba(255, 255, 255, 0.38);
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: rgba(255, 255, 255, 0.38);
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon {
  color: rgba(255, 255, 255, 0.38);
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active {
  opacity: 1;
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #3f51b5;
  color: rgba(255, 255, 255, 0.38);
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: rgba(255, 255, 255, 0.38);
}

.e-tab.e-background.e-accent .e-tab-header .e-indicator {
  background: #e3165b;
}

.e-tab.e-background.e-accent .e-tab-header.e-disable .e-indicator {
  background: rgba(227, 22, 91, 0.38);
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: rgba(255, 255, 255, 0.12);
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-close-icon {
  color: rgba(255, 255, 255, 0.7);
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: rgba(255, 255, 255, 0.12);
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: white;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap {
  background: rgba(63, 81, 181, 0);
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap:focus,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap:focus {
  background: rgba(255, 255, 255, 0.12);
}

.e-tab.e-background.e-focused .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: rgba(255, 255, 255, 0.87);
}

.e-tab.e-background.e-focused .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background.e-focused .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: rgba(255, 255, 255, 0.87);
}

.e-tab.e-rtl .e-tab-header .e-hscroll.e-rtl .e-scroll-nav {
  border: 0;
}

.e-tab .e-content {
  background: inherit;
}

.e-tab .e-content .e-item {
  background: inherit;
  color: #000;
}

@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

/*! TreeView's material theme wise override definitions and variables */
.e-treeview .e-list-item div.e-icons.interaction {
  -webkit-transition: -webkit-transform .3s ease-in-out;
  border-radius: 15px;
  transition: transform .3s ease-in-out;
}

.e-treeview .e-list-item .e-icons.e-icon-collapsible {
  transform: rotate(90deg);
}

.e-treeview.e-drag-item.e-rtl .e-icons.e-drop-next {
  transform: rotate(180deg);
}

.e-treeview.e-rtl div.e-icons {
  transform: rotate(180deg);
}

/*! TreeView icons */
.e-treeview .e-list-item div.e-icons::before {
  content: '\e22f';
}

.e-treeview .e-sibling::before {
  content: '';
}

.e-treeview .e-popup .e-icons::before {
  content: '\e930';
}

.e-treeview.e-drag-item .e-icons.e-drop-in::before {
  content: '\e22c';
}

.e-treeview.e-drag-item .e-icons.e-drop-out::before {
  content: '\e22b';
}

.e-treeview.e-drag-item .e-icons.e-drop-next::before {
  content: '\e22d';
}

.e-treeview.e-drag-item .e-icons.e-no-drop::before {
  content: '\e22a';
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.e-bigger .e-treeview .e-navigable .e-text-content,
.e-treeview.e-bigger .e-navigable .e-text-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.e-bigger .e-treeview .e-navigable .e-list-text,
.e-treeview.e-bigger .e-navigable .e-list-text {
  padding: 0;
  width: 100%;
}

.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-text,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-text {
  padding: 0;
}

.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 10px 0 6px;
}

.e-bigger .e-treeview .e-navigable .e-anchor-wrap,
.e-treeview.e-bigger .e-navigable .e-anchor-wrap {
  padding: 0 0 0 10px;
}

.e-bigger .e-treeview .e-navigable .e-list-icon,
.e-bigger .e-treeview .e-navigable .e-list-img,
.e-bigger .e-treeview .e-navigable .e-list-icon + .e-list-img,
.e-treeview.e-bigger .e-navigable .e-list-icon,
.e-treeview.e-bigger .e-navigable .e-list-img,
.e-treeview.e-bigger .e-navigable .e-list-icon + .e-list-img {
  margin: 0 10px 0 0;
}

.e-bigger .e-treeview .e-fullrow,
.e-treeview.e-bigger .e-fullrow {
  height: 40px;
}

.e-bigger .e-treeview .e-list-text,
.e-treeview.e-bigger .e-list-text {
  line-height: 38px;
  min-height: 38px;
  padding: 0 10px;
}

.e-bigger .e-treeview .e-list-text .e-input-group,
.e-treeview.e-bigger .e-list-text .e-input-group {
  height: 38px;
}

.e-bigger .e-treeview .e-checkbox-wrapper,
.e-treeview.e-bigger .e-checkbox-wrapper {
  margin: 0 0 0 10px;
}

.e-bigger .e-treeview .e-checkbox-wrapper + .e-list-icon,
.e-bigger .e-treeview .e-checkbox-wrapper + .e-list-img,
.e-treeview.e-bigger .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-bigger .e-checkbox-wrapper + .e-list-img {
  margin: 0 0 0 16px;
}

.e-bigger .e-treeview .e-list-icon,
.e-bigger .e-treeview .e-list-img,
.e-treeview.e-bigger .e-list-icon,
.e-treeview.e-bigger .e-list-img {
  margin: 0 0 0 10px;
}

.e-bigger .e-treeview .e-list-icon + .e-list-icon,
.e-bigger .e-treeview .e-list-icon + .e-list-img,
.e-bigger .e-treeview .e-list-img + .e-list-icon,
.e-bigger .e-treeview .e-list-img + .e-list-img,
.e-treeview.e-bigger .e-list-icon + .e-list-icon,
.e-treeview.e-bigger .e-list-icon + .e-list-img,
.e-treeview.e-bigger .e-list-img + .e-list-icon,
.e-treeview.e-bigger .e-list-img + .e-list-img {
  margin: 0 0 0 10px;
}

.e-bigger .e-treeview .e-icon-collapsible::before,
.e-bigger .e-treeview .e-icon-expandable::before,
.e-treeview.e-bigger .e-icon-collapsible::before,
.e-treeview.e-bigger .e-icon-expandable::before {
  padding: 6px;
}

.e-bigger .e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 6px 0 10px;
}

.e-bigger .e-treeview.e-rtl .e-navigable .e-anchor-wrap,
.e-treeview.e-bigger.e-rtl .e-navigable .e-anchor-wrap {
  padding: 0 10px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-navigable .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-navigable .e-list-img,
.e-bigger .e-treeview.e-rtl .e-navigable .e-list-icon + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-icon + .e-list-img {
  margin: 0 0 0 10px;
}

.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper {
  margin: 0 10px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper + .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper + .e-list-img {
  margin: 0 16px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-img {
  margin: 0 10px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-list-icon + .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-icon + .e-list-img,
.e-bigger .e-treeview.e-rtl .e-list-img + .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-img + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-icon + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-icon + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-img + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-img + .e-list-img {
  margin: 0 10px 0 0;
}

.e-treeview {
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.e-treeview > .e-ul {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.e-treeview .e-ul {
  margin: 0;
  padding: 0 0 0 24px;
}

.e-treeview li.e-node-collapsed .e-list-item .e-fullrow {
  display: none;
}

.e-treeview .e-display-none {
  display: none;
}

.e-treeview .e-list-item {
  list-style: none;
  padding: 2px 0;
}

.e-treeview .e-list-item .e-ul {
  margin: 2px 0 -2px;
  padding: 0 0 0 24px;
}

.e-treeview .e-list-item.e-disable > .e-text-content,
.e-treeview .e-list-item.e-disable > .e-fullrow {
  -ms-touch-action: none;
  filter: alpha(opacity=50);
  opacity: .5;
  pointer-events: none;
  touch-action: none;
}

.e-treeview .e-list-item .e-icons.e-icons-spinner::before {
  content: none;
}

.e-treeview .e-icons .e-spinner-pane {
  position: relative;
}

.e-treeview .e-text-content {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  cursor: pointer;
  margin: 0;
  padding: 0 0 0 24px;
}

.e-treeview .e-fullrow {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  box-sizing: border-box;
  cursor: pointer;
  height: 32px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.e-treeview .e-checkbox-wrapper {
  margin: 0 0 0 5px;
  pointer-events: all;
  position: relative;
}

.e-treeview .e-checkbox-wrapper + .e-list-icon,
.e-treeview .e-checkbox-wrapper + .e-list-img {
  margin: 0 0 0 12px;
}

.e-treeview .e-checkbox-wrapper + .e-list-text {
  padding: 0 10px;
}

.e-treeview .e-checkbox-wrapper .e-ripple-container {
  bottom: -7px;
  height: 32px;
  left: -7px;
  right: -7px;
  top: -7px;
  width: 32px;
}

.e-treeview .e-list-text {
  box-sizing: border-box;
  display: inline-block;
  line-height: 30px;
  margin: 0;
  min-height: 30px;
  padding: 0 5px;
  text-decoration: none;
  vertical-align: middle;
}

.e-treeview .e-list-text .e-input-group {
  height: 30px;
  margin-bottom: 0;
  min-width: 150px;
  vertical-align: bottom;
}

.e-treeview .e-list-icon,
.e-treeview .e-list-img {
  display: inline-block;
  height: 18px;
  margin: 0 0 0 5px;
  vertical-align: middle;
  width: 18px;
}

.e-treeview .e-list-icon + .e-list-icon,
.e-treeview .e-list-icon + .e-list-img,
.e-treeview .e-list-img + .e-list-icon,
.e-treeview .e-list-img + .e-list-img {
  margin: 0 0 0 10px;
}

.e-treeview .e-list-icon + .e-list-text,
.e-treeview .e-list-img + .e-list-text {
  padding: 0 10px;
}

.e-treeview .e-icon-collapsible,
.e-treeview .e-icon-expandable {
  display: inline-block;
  height: 24px;
  margin: 0 0 0 -24px;
  vertical-align: middle;
  width: 24px;
}

.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  display: inline-block;
  padding: 7px;
}

.e-treeview .e-load {
  animation: rotation .5s infinite linear;
}

.e-treeview .e-sibling {
  border-radius: 10px;
  height: 6px;
  margin-top: -5px;
  position: absolute;
  width: 6px;
  z-index: 2;
}

.e-treeview .e-text-content + .e-sibling {
  margin-top: -1px;
}

.e-treeview .e-sibling::before {
  left: 6px;
  top: 3px;
  height: 1px;
  position: absolute;
  width: 144px;
  z-index: 2;
}

.e-treeview .e-popup {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
  position: absolute;
  z-index: 99999;
}

.e-treeview .e-popup .e-content {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  padding: 4px;
}

.e-treeview .e-popup .e-icons {
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  height: 26px;
  line-height: 18px;
  padding: 4px;
  width: 26px;
}

.e-treeview .e-popup .e-downtail::before,
.e-treeview .e-popup .e-downtail::after {
  border: 10px solid transparent;
  content: '';
  height: 0;
  left: 8px;
  position: absolute;
  width: 0;
}

.e-treeview .e-popup .e-downtail::after {
  bottom: -18px;
}

.e-treeview.e-fullrow-wrap .e-text-content {
  pointer-events: none;
  position: relative;
}

.e-treeview.e-fullrow-wrap .e-icon-collapsible,
.e-treeview.e-fullrow-wrap .e-icon-expandable,
.e-treeview.e-fullrow-wrap .e-input,
.e-treeview.e-fullrow-wrap .e-list-url {
  pointer-events: auto;
}

.e-treeview .e-navigable .e-text-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.e-treeview .e-navigable .e-list-url {
  padding: 0;
  width: 100%;
}

.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 0 0 10px;
}

.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 10px 0 2px;
}

.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon + .e-list-img {
  margin: 0 10px 0 0;
}

.e-treeview .e-navigable .e-anchor-wrap {
  padding: 0 0 0 5px;
}

.e-treeview .e-navigable .e-list-icon,
.e-treeview .e-navigable .e-list-img {
  margin: 0 10px 0 0;
}

.e-treeview.e-drag-item {
  overflow: visible;
  z-index: 10000;
}

.e-treeview.e-drag-item .e-text-content {
  float: left;
}

.e-treeview.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-drag-item .e-icon-expandable::before {
  font-size: 12px;
  padding: 6px;
}

.e-treeview.e-drag-item .e-drop-count {
  border: 1px solid;
  border-radius: 15px;
  box-sizing: content-box;
  font-size: 13px;
  line-height: normal;
  min-width: 12px;
  padding: 3px 5px 4px;
  margin-left: -12px;
  position: absolute;
  text-align: center;
  top: -10px;
}

.e-treeview.e-dragging .e-text-content,
.e-treeview.e-dragging .e-fullrow {
  cursor: default;
}

.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 10px 0 0;
}

.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 2px 0 10px;
}

.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon + .e-list-img {
  margin: 0 0 0 10px;
}

.e-treeview.e-rtl .e-navigable .e-anchor-wrap {
  padding: 0 5px 0 0;
}

.e-treeview.e-rtl .e-navigable .e-list-icon,
.e-treeview.e-rtl .e-navigable .e-list-img,
.e-treeview.e-rtl .e-navigable .e-list-icon + .e-list-img {
  margin: 0 0 0 10px;
}

.e-treeview.e-rtl .e-ul {
  padding: 0 24px 0 0;
}

.e-treeview.e-rtl .e-list-item .e-ul {
  padding: 0 24px 0 0;
}

.e-treeview.e-rtl .e-text-content {
  padding: 0 24px 0 0;
}

.e-treeview.e-rtl .e-checkbox-wrapper {
  margin: 0 5px 0 0;
}

.e-treeview.e-rtl .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-rtl .e-checkbox-wrapper + .e-list-img {
  margin: 0 12px 0 0;
}

.e-treeview.e-rtl .e-list-icon,
.e-treeview.e-rtl .e-list-img {
  margin: 0 5px 0 0;
}

.e-treeview.e-rtl .e-list-icon + .e-list-icon,
.e-treeview.e-rtl .e-list-icon + .e-list-img,
.e-treeview.e-rtl .e-list-img + .e-list-icon,
.e-treeview.e-rtl .e-list-img + .e-list-img {
  margin: 0 10px 0 0;
}

.e-treeview.e-rtl .e-icon-collapsible,
.e-treeview.e-rtl .e-icon-expandable {
  margin: 0 -24px 0 0;
}

.e-treeview.e-rtl .e-sibling::before {
  right: 6px;
  top: 3px;
}

.e-treeview.e-rtl.e-drag-item .e-text-content {
  float: right;
}

.e-treeview.e-rtl.e-drag-item .e-drop-count {
  margin-right: -12px;
}

.e-treeview.e-disabled .e-fullrow,
.e-treeview.e-disabled .e-icons,
.e-treeview.e-disabled .e-text-content,
.e-treeview.e-disabled .e-list-img,
.e-treeview.e-disabled .e-list-icon {
  cursor: auto;
}

.e-treeview.e-disabled .e-list-url {
  cursor: default;
  pointer-events: none;
}

.e-bigger .e-treeview .e-list-text,
.e-treeview.e-bigger .e-list-text {
  font-size: 14px;
}

.e-bigger .e-treeview .e-icon-collapsible::before,
.e-bigger .e-treeview .e-icon-expandable::before,
.e-treeview.e-bigger .e-icon-collapsible::before,
.e-treeview.e-bigger .e-icon-expandable::before {
  font-size: 12px;
}

.e-treeview {
  -webkit-tap-highlight-color: transparent;
}

.e-treeview .e-text-content,
.e-treeview .e-fullrow {
  border-color: transparent;
}

.e-treeview .e-list-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
}

.e-treeview .e-list-icon,
.e-treeview .e-list-img {
  font-size: 18px;
}

.e-treeview .e-icon-collapsible,
.e-treeview .e-icon-expandable {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  font-size: 10px;
}

.e-treeview .e-list-item.e-active,
.e-treeview .e-list-item.e-hover {
  background: transparent;
}

.e-treeview .e-list-item.e-hover > .e-text-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-treeview .e-list-item.e-hover > .e-text-content .e-list-text {
  color: rgba(0, 0, 0, 0.87);
}

.e-treeview .e-list-item.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-hover > .e-text-content .e-icon-expandable {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview .e-list-item.e-active > .e-text-content {
  color: #e3165b;
}

.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: #e3165b;
}

.e-treeview .e-list-item.e-active > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active > .e-text-content .e-icon-expandable {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview .e-list-item.e-active.e-hover > .e-text-content {
  color: #e3165b;
}

.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-list-text {
  color: #e3165b;
}

.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-icon-expandable {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview .e-list-item.e-editing.e-active > .e-text-content,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-list-text,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-list-text {
  color: rgba(0, 0, 0, 0.87);
}

.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-icon-expandable,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-icon-expandable {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview .e-list-item.e-hover > .e-fullrow {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.e-treeview .e-list-item.e-active > .e-fullrow {
  background-color: #eee;
  border-color: #eee;
}

.e-treeview .e-list-item.e-active.e-animation-active > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
}

.e-treeview .e-list-item.e-active.e-animation-active > .e-text-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-treeview .e-list-item.e-active.e-animation-active > .e-text-content .e-list-text {
  color: rgba(0, 0, 0, 0.87);
}

.e-treeview .e-list-item.e-active.e-hover > .e-fullrow {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.e-treeview .e-list-item.e-editing.e-active > .e-fullrow,
.e-treeview .e-list-item.e-editing.e-hover > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
}

.e-treeview .e-list-item.e-disable > .e-text-content,
.e-treeview .e-list-item.e-disable > .e-fullrow {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview .e-list-item.e-disable .e-icon-collapsible,
.e-treeview .e-list-item.e-disable .e-icon-expandable {
  color: rgba(0, 0, 0, 0.87);
}

.e-treeview .e-sibling {
  background: #e3165b;
}

.e-treeview .e-sibling::before {
  background: rgba(0, 0, 0, 0.54);
}

.e-treeview .e-popup .e-content {
  background-color: #fff;
  border-color: #eee;
}

.e-treeview .e-popup.e-select .e-icons {
  border-color: #eee;
}

.e-treeview .e-popup .e-downtail::before {
  border-top-color: #eee;
}

.e-treeview .e-popup .e-downtail::after {
  border-top-color: #fff;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-hover > .e-text-content {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active > .e-text-content {
  background-color: #eee;
  border-color: #eee;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active.e-hover > .e-text-content {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-editing.e-active > .e-text-content,
.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-editing.e-hover > .e-text-content {
  background-color: transparent;
  border-color: transparent;
}

.e-treeview.e-fullrow-wrap .e-text-content {
  border-color: transparent;
}

.e-treeview.e-drag-item {
  background-color: #eee;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
}

.e-treeview.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-drag-item .e-icon-expandable::before {
  font-size: 12px;
}

.e-treeview.e-drag-item .e-list-text {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview.e-drag-item .e-icons {
  color: rgba(0, 0, 0, 0.54);
}

.e-treeview.e-drag-item .e-drop-count {
  background-color: #e3165b;
  border-color: #fff;
  color: #fff;
}

.e-treeview.e-drag-item.e-rtl .e-sibling {
  background: #e3165b;
}

/*! Material theme definition's */
/*! component's theme wise override definitions and variables */
ejs-sidebar {
  display: block;
}

.e-sidebar {
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: none;
  vertical-align: middle;
  visibility: hidden;
  will-change: transform;
}

.e-sidebar.e-right.e-open {
  transform: translateX(0%);
  transition: transform .5s ease;
  visibility: visible;
}

.e-sidebar.e-right.e-close {
  box-shadow: none;
  transform: translateX(100%);
  transition: transform .5s ease, visibility 500ms;
  visibility: hidden;
}

.e-sidebar.e-left.e-open {
  transform: translateX(0%);
  transition: transform .5s ease;
  visibility: visible;
}

.e-sidebar.e-left.e-transition.e-close, .e-sidebar.e-right.e-transition.e-close {
  transition: transform .5s ease, visibility 500ms;
}

.e-sidebar.e-left.e-close {
  box-shadow: none;
  transform: translateX(-100%);
  visibility: hidden;
}

.e-sidebar.e-right.e-close {
  box-shadow: none;
  transform: translateX(100%);
  transition: transform .5s ease, visibility 500ms;
  visibility: hidden;
}

.e-sidebar.e-right {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  left: auto;
  right: 0;
  top: 0;
}

.e-sidebar.e-left {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  top: 0;
}

.e-sidebar.e-left.e-close.e-dock {
  transform: translateX(0%);
  transition: width .5s ease, visibility 500ms;
  visibility: visible;
}

.e-sidebar.e-right.e-close.e-dock {
  transform: translateX(0%);
  transition: width .5s ease, visibility 500ms;
  visibility: visible;
}

.e-sidebar.e-left.e-open.e-disable-animation, .e-sidebar.e-right.e-open.e-disable-animation, .e-sidebar.e-right.e-close.e-disable-animation, .e-sidebar.e-left.e-close.e-disable-animation {
  transition: none;
}

.e-sidebar.e-visibility {
  visibility: hidden;
}

.e-sidebar.e-over {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.18);
}

.e-sidebar-absolute {
  position: absolute;
}

.e-sidebar-context {
  overflow-x: hidden;
  position: relative;
}

.e-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  top: 0;
  width: auto;
  z-index: 999;
}

.e-content-animation {
  transition: margin .5s ease, transform .5s ease;
}

.e-content-animation.e-overlay {
  box-sizing: border-box;
  overflow: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-disable-interaction {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-sidebar-overflow {
  overflow-x: hidden !important;
}

.e-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-content-placeholder.e-sidebar.e-placeholder-sidebar {
  left: 0;
  position: fixed;
  right: auto;
  top: 0;
  visibility: visible;
}

/*! component's theme wise override definitions and variables */
@keyframes hscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! component's theme wise override definitions and variables */
@keyframes vscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

/*! menu layout */
.e-menu-wrapper ul.e-vertical .e-menu-item .e-caret::before {
  content: '\e956';
}

.e-menu-wrapper ul .e-menu-item .e-caret::before {
  content: '\e94d';
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret::before {
  content: '\e956';
}

.e-menu-wrapper.e-hamburger .e-menu-header .e-menu-icon::before {
  content: '\e99a';
}

.e-menu-wrapper.e-hamburger.e-close-icon .e-menu-header .e-menu-icon::before {
  content: '\eb36';
}

.e-menu-wrapper.e-hamburger .e-vertical .e-menu-item .e-caret::before,
.e-menu-wrapper.e-hamburger .e-menu-item .e-caret::before {
  content: '\e94d';
}

.e-menu-wrapper.e-rtl.e-hamburger ul.e-ul .e-caret::before {
  content: '\e94d';
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-nav-left-arrow::before,
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-nav-right-arrow::before {
  content: '\e956';
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-nav-up-arrow::before,
.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-nav-down-arrow::before {
  content: '\e94d';
}

.e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before {
  content: '\e937';
}

.e-rtl.e-menu-wrapper ul.e-ul .e-caret::before {
  content: '\e937';
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before {
  content: '\e937';
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-caret::before {
  content: '\e937';
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-ul .e-caret::before {
  content: '\e94d';
}

/*! menu layout */
.e-menu-wrapper {
  border: none;
  border-radius: 0;
  display: inline-block;
  line-height: 0;
}

.e-menu-wrapper .e-menu-vscroll {
  height: inherit;
}

.e-menu-wrapper ul {
  font-weight: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 13px;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}

.e-menu-wrapper ul.e-ul,
.e-menu-wrapper ul.e-ul * {
  box-sizing: border-box;
}

.e-menu-wrapper ul.e-ul:focus,
.e-menu-wrapper ul.e-ul *:focus {
  outline: none;
}

.e-menu-wrapper ul.e-vertical {
  min-width: 120px;
}

.e-menu-wrapper ul.e-vertical .e-menu-item {
  display: list-item;
}

.e-menu-wrapper ul.e-vertical .e-menu-item.e-blankicon {
  padding-left: 44px;
}

.e-menu-wrapper ul.e-vertical .e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
}

.e-menu-wrapper ul.e-menu {
  display: inline-block;
}

.e-menu-wrapper ul.e-menu:not(.e-vertical) {
  padding: 0;
}

.e-menu-wrapper ul.e-menu:not(.e-vertical) .e-separator {
  border-right-style: solid;
  border-right-width: 1px;
  padding: 0;
}

.e-menu-wrapper ul .e-menu-item {
  cursor: pointer;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 42px;
  line-height: 42px;
  padding: 0 12px;
  vertical-align: top;
  white-space: nowrap;
}

.e-menu-wrapper ul .e-menu-item.e-menu-hide {
  display: none;
}

.e-menu-wrapper ul .e-menu-item.e-menu-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.e-menu-wrapper ul .e-menu-item .e-menu-url {
  text-decoration: none;
}

.e-menu-wrapper ul .e-menu-item .e-menu-icon {
  display: inline-block;
  vertical-align: middle;
}

.e-menu-wrapper ul .e-menu-item.e-separator {
  cursor: auto;
  line-height: normal;
  pointer-events: none;
}

.e-menu-wrapper ul .e-menu-item .e-menu-icon {
  font-size: 14px;
  height: auto;
  line-height: 42px;
  margin-right: 8px;
  text-align: center;
  width: 24px;
}

.e-menu-wrapper ul .e-menu-item .e-caret {
  font-size: 10px;
  height: auto;
  line-height: 42px;
  position: absolute;
  right: 12px;
  width: auto;
}

.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon {
  padding-right: 30px;
}

.e-menu-wrapper ul .e-menu-item.e-disabled {
  cursor: auto;
  opacity: 0.38;
  pointer-events: none;
}

.e-menu-wrapper .e-ul {
  font-size: 14px;
  padding: 8px 0;
  min-width: 120px;
}

.e-menu-wrapper .e-ul .e-menu-item {
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  display: list-item;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-icon {
  font-size: 14px;
  line-height: 36px;
  margin-right: 10px;
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret {
  line-height: 36px;
  margin-left: 16px;
  margin-right: 0;
  position: absolute;
  right: 8px;
}

.e-menu-wrapper .e-ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}

.e-menu-wrapper .e-ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
  margin: 8px 0;
}

.e-menu-wrapper .e-ul .e-menu-item.e-blankicon {
  padding-left: 40px;
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret {
  font-size: 11px;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-icon {
  width: 1em;
}

.e-menu-wrapper.e-menu-icon-right .e-menu-header .e-menu-icon {
  float: right;
}

.e-menu-wrapper.e-menu-icon-right .e-menu-header .e-menu-title {
  padding: 0 16px;
}

.e-menu-wrapper .e-menu-header {
  display: none;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 42px;
  text-align: left;
  white-space: nowrap;
}

.e-menu-wrapper .e-menu-header .e-menu-title,
.e-menu-wrapper .e-menu-header .e-menu-icon {
  display: inline-block;
  line-height: inherit;
}

.e-menu-wrapper .e-menu-header .e-menu-icon {
  cursor: pointer;
  float: left;
  outline: none;
  padding: 0 16px;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
  padding: 0 30px;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  width: 30px;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus, .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  border: 0;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-right-nav {
  border-left: none;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-left-nav {
  border-right: none;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-hscroll-bar {
  overflow-y: hidden;
}

.e-menu-wrapper .e-scroll-nav .e-nav-arrow {
  font-size: 10px;
}

.e-menu-wrapper .e-scroll-nav .e-nav-arrow.e-nav-left-arrow {
  transform: rotate(180deg);
}

.e-menu-wrapper .e-scroll-nav .e-nav-arrow.e-nav-up-arrow {
  transform: rotate(180deg);
}

.e-menu-wrapper.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-left-arrow {
  transform: rotate(0deg);
}

.e-menu-wrapper.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-right-arrow {
  transform: rotate(180deg);
}

.e-menu-wrapper.e-popup.e-menu-popup {
  border: none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: absolute;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device) {
  padding: 16px 0;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav {
  transform: none;
  width: 100%;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav .e-nav-down-arrow {
  transform: none;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-vscroll-bar {
  height: 100%;
  width: inherit;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-vscroll-bar .e-vscroll-content {
  width: inherit;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav {
  height: 16px;
}

.e-menu-wrapper.e-scrollable {
  display: block;
}

.e-menu-wrapper.e-scrollable .e-menu {
  display: block;
  overflow: auto;
}

.e-rtl.e-menu-wrapper ul .e-menu-item {
  text-align: right;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon {
  margin-right: 0;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret {
  margin-left: 0;
  margin-right: 16px;
  right: auto;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon {
  margin-left: 8px;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret {
  left: 12px;
}

.e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon {
  padding-left: 30px;
  padding-right: 12px;
}

.e-rtl.e-menu-wrapper ul.e-vertical .e-menu-item.e-blankicon {
  padding-right: 44px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon {
  margin-left: 10px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret {
  left: 8px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 16px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon {
  padding-left: 16px;
  padding-right: 40px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-bigger .e-menu-wrapper ul,
.e-bigger.e-menu-wrapper ul {
  font-size: 14px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item,
.e-bigger.e-menu-wrapper ul .e-menu-item {
  height: 56px;
  line-height: 56px;
  padding: 0 16px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul .e-menu-item .e-menu-icon {
  font-size: 16px;
  line-height: 56px;
  margin-right: 10px;
  width: 24px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul .e-menu-item .e-caret {
  font-size: 12px;
  line-height: 56px;
  right: 16px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul .e-menu-item.e-separator {
  padding: 0;
}

.e-bigger .e-menu-wrapper ul.e-menu:not(.e-vertical),
.e-bigger.e-menu-wrapper ul.e-menu:not(.e-vertical) {
  padding: 0;
}

.e-bigger .e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}

.e-bigger .e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-blankicon,
.e-bigger.e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-blankicon {
  padding-left: 50px;
}

.e-bigger .e-menu-wrapper ul.e-ul,
.e-bigger.e-menu-wrapper ul.e-ul {
  font-size: 15px;
  padding: 8px 0;
  white-space: nowrap;
  max-width: 280px;
  min-width: 112px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item {
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon {
  font-size: 16px;
  line-height: 48px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-caret {
  line-height: 48px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon {
  padding-left: 42px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-caret {
  font-size: 12px;
  right: 8px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon {
  margin-right: 10px;
  width: 1em;
}

.e-bigger .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-bigger.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
  padding: 0 36px;
}

.e-bigger .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-bigger.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  width: 36px;
}

.e-bigger .e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-bigger.e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device) {
  padding: 24px 0;
}

.e-bigger .e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-bigger.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav {
  height: 24px;
}

.e-bigger .e-menu-wrapper.e-menu-popup,
.e-bigger.e-menu-wrapper.e-menu-popup {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-bigger .e-menu-wrapper .e-scroll-nav .e-icons,
.e-bigger.e-menu-wrapper .e-scroll-nav .e-icons {
  font-size: 12px;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item {
  text-align: right;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon {
  margin-left: 10px;
  margin-right: 0;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret {
  left: 16px;
  margin-left: 0;
  right: auto;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 16px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-vertical .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-vertical .e-menu-item.e-blankicon {
  padding-right: 50px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon {
  margin-left: 10px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret {
  left: 8px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 16px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon {
  padding-left: 16px;
  padding-right: 42px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-menu-wrapper.e-hamburger {
  border: 0;
  display: block;
  position: relative;
}

.e-menu-wrapper.e-hamburger .e-menu-header:not(.e-vertical) {
  border: none;
  display: block;
}

.e-menu-wrapper.e-hamburger .e-popup.e-menu-popup {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: block;
  position: relative;
  width: 100%;
}

.e-menu-wrapper.e-hamburger ul.e-menu {
  border: none;
  overflow-y: auto;
  width: 100%;
}

.e-menu-wrapper.e-hamburger ul.e-menu.e-menu-parent.e-hide-menu {
  display: none;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item {
  text-indent: 12px;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 34px;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon {
  display: inline;
  text-indent: 0;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-hide {
  display: none;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item[aria-expanded="true"] .e-caret {
  transition: transform .3s ease-in-out;
  transform: rotate(-180deg);
  text-indent: 0;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item[aria-expanded="false"] .e-caret {
  transition: transform .3s ease-in-out;
  transform: rotate(0deg);
  text-indent: 0;
}

.e-menu-wrapper.e-hamburger ul.e-menu:not(.e-vertical) {
  border-top: 0;
  display: block;
  padding: 0;
  position: absolute;
}

.e-menu-wrapper.e-hamburger ul.e-ul {
  font-size: 13px;
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item {
  line-height: 42px;
  text-indent: inherit;
}

.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item.e-blankicon {
  padding: 0;
  text-indent: inherit;
}

.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item .e-caret {
  font-size: 10px;
  right: 12px;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item {
  display: list-item;
  height: auto;
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item.e-menu-caret-icon {
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
  text-indent: 0;
  width: 100%;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item.e-blankicon {
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
}

.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-caret-icon {
  padding-left: 0;
  padding-right: 0;
}

.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-icon {
  margin-left: 0;
  text-indent: inherit;
}

.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-caret {
  left: 12px;
  right: auto;
}

.e-bigger .e-menu-wrapper.e-hamburger ul .e-ul,
.e-bigger.e-menu-wrapper.e-hamburger ul .e-ul {
  max-width: 100%;
}

.e-bigger .e-menu-wrapper.e-hamburger ul .e-ul .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul .e-ul .e-menu-item {
  height: auto;
  line-height: 56px;
  padding: 0;
}

.e-bigger .e-menu-wrapper.e-hamburger .e-menu-header,
.e-bigger.e-menu-wrapper.e-hamburger .e-menu-header {
  font-size: 16px;
  height: 56px;
  line-height: 56px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item {
  text-indent: 16px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 42px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul {
  font-size: 14px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item {
  text-indent: inherit;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret {
  font-size: 12px;
  right: 16px;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item {
  padding: 0;
  text-indent: 16px;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 42px;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon {
  margin-left: 0;
  text-indent: inherit;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon {
  padding-left: 0;
  padding-right: 0;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-caret {
  left: 12px;
  right: auto;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item {
  text-indent: inherit;
}

/*! menu theme */
.e-menu-wrapper {
  background-color: #fafafa;
}

.e-menu-wrapper ul {
  background-color: inherit;
  color: rgba(0, 0, 0, 0.87);
}

.e-menu-wrapper ul.e-menu:not(.e-vertical) .e-separator {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.e-menu-wrapper ul .e-menu-item.e-menu-header {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.e-menu-wrapper ul .e-menu-item .e-caret {
  color: rgba(0, 0, 0, 0.54);
}

.e-menu-wrapper ul .e-menu-item .e-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-menu-wrapper ul .e-menu-item.e-menu-header {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.e-menu-wrapper ul .e-menu-item .e-menu-url {
  color: rgba(0, 0, 0, 0.87);
}

.e-menu-wrapper ul .e-menu-item.e-focused {
  color: initial;
  outline: 0 solid rgba(0, 0, 0, 0.12);
  outline-offset: 0;
  background-color: #eee;
}

.e-menu-wrapper ul .e-menu-item.e-focused .e-caret {
  color: rgba(0, 0, 0, 0.54);
}

.e-menu-wrapper ul .e-menu-item.e-focused .e-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-menu-wrapper ul .e-menu-item.e-selected {
  color: rgba(0, 0, 0, 0.87);
  outline: 0 solid #e0e0e0;
  outline-offset: 0;
  background-color: #e0e0e0;
}

.e-menu-wrapper ul .e-menu-item.e-selected .e-caret {
  color: rgba(0, 0, 0, 0.54);
}

.e-menu-wrapper ul .e-menu-item.e-selected .e-menu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-menu-wrapper ul .e-menu-item.e-separator {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.e-menu-wrapper ul .e-disabled {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.e-menu-wrapper ul .e-disabled .e-menu-icon {
  color: rgba(0, 0, 0, 0.38);
}

.e-menu-wrapper ul .e-disabled .e-caret {
  color: rgba(0, 0, 0, 0.38);
}

.e-menu-wrapper ul .e-disabled .e-menu-url {
  color: rgba(0, 0, 0, 0.38);
}

.e-menu-wrapper .e-ul {
  background-color: inherit;
  color: rgba(0, 0, 0, 0.87);
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-url {
  color: rgba(0, 0, 0, 0.87);
}

.e-menu-wrapper .e-ul .e-menu-item.e-focused {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
  outline: 0 solid rgba(0, 0, 0, 0.12);
  outline-offset: 0;
}

.e-menu-wrapper .e-ul .e-menu-item.e-selected {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
  outline: 0 solid #eee;
  outline-offset: 0;
}

.e-menu-wrapper .e-ul .e-menu-item.e-separator {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.e-menu-wrapper.e-menu-popup {
  background-color: #fff;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav {
  background: #fafafa;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav .e-nav-arrow.e-icons {
  color: rgba(0, 0, 0, 0.54);
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:hover {
  background: #eee;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:focus {
  background: #eee;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:active {
  background: #eee;
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.12);
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav .e-icons {
  color: rgba(0, 0, 0, 0.87);
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:hover, .e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:focus {
  background: #eee;
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:active {
  background: #eee;
}

.e-menu-wrapper.e-hamburger .e-menu-header {
  color: rgba(0, 0, 0, 0.87);
}

.e-menu-wrapper.e-hamburger .e-menu-header .e-menu-icon {
  color: rgba(0, 0, 0, 0.87);
}

.e-menu-wrapper.e-hamburger ul {
  color: rgba(0, 0, 0, 0.87);
}
