@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css";
@import "../node_modules//@syncfusion/ej2-angular-grids/styles/material.css";
@import "~dhtmlx-scheduler/codebase/dhtmlxscheduler.css";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 1.2rem !important;
}
.confirm-modal {
  display: flex;
  top: 40%;
  background-color: gray;
  border: 1px solid lightgray;
}

.confirm-modal .modal-header {
  background-color: #f0f1f3;
}
